import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import { Formatter, Spinner, NoResults } from 'shared'

const insertLineBreaks = (text, maxLength) => {
  if (text == null) {
    return null;
  }

  let result = '';
  for (let i = 0; i < text.length; i += maxLength) {
    result += text.slice(i, i + maxLength) + '\n';
  }
  
  return result.trim();
};

export default class ParkReservationSlots extends Component {
  render() {
    let { date, timeSlots } = this.props
    if (!date) return null
    return (
      <div className="park-reservation-time-slots">
        {timeSlots ?
          timeSlots.length ?
            timeSlots.map(ts => this.renderItem(ts))
            :
            <NoResults className='compact'>No time available</NoResults>
          :
          <>
            <Spinner />
            <div style={{height: '300px'}}></div>
          </>
        }
      </div>
    )
  }

  renderItem(slot) {
    let { base, subroute, park, reserve, cancel } = this.props
    let { time, reservation, available, militaryTime } = slot
    let options = { }

    if (reservation) {
      options = {
        text: (
          <>
            Cancel: {slot.desc}
             {/*{reservation.user_name && <br />}{reservation.user_name}
             {reservation.note && <br />}{insertLineBreaks(reservation.note, 23)}*/}
          </>
        ),
        className: 'btn-sm park-reservation-btn',
        disabled: !reservation.cancellable,
        inverted: true,
        click: () => cancel(slot),
        key: time,
      }
    } else {
      options = {
        text: (
          <>
            Reserve: {slot.desc}
             {/*{slot.res_user_name && <br />}{slot.res_user_name}
             {slot.res_note && <br />}{insertLineBreaks(slot.res_note, 23)}*/}
          </>
        ),
        className: classNames('btn-sm park-reservation-btn', { gray: !available }),
        disabled: !available,
        click: () => reserve(slot),
        key: time,
      }
    }

    if (User.current && User.current.guest) {
      options.click = () => {
        App.state.setObjectSilent({ afterSignupPath: App.path })
        App.go(`${this.props.base}/guest-signup`)
      }
    }

    if (!reservation && park.requiresReservationDetails) {
      let url = subroute == null ? `${base}/${park.uid}` : `${base}/${park.uid}::${subroute}:${militaryTime}`
      return <SmartButtonLink to={url} {...options} click={null} />
    } else {
      return <SmartButtonStub {...options} />
    }
  }
}
